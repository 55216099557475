import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next/withAnalyticsEvents';

import { getMentionsConfig } from './mentions-helpers';
import type { MentionContext, MentionsConfig } from './types';

export function createMentionsConfig(
	cloudId: string,
	accountId: string | null,
	mentionContext?: MentionContext,
	withAnalyticsEventProps?: WithAnalyticsEventsProps,
	onInviteItemClick?: () => void,
	userRole?: 'admin' | 'trusted' | 'basic',
	isEligibleXProductUserInvite?: () => Promise<boolean>,
	onXProductUserInviteClick?: (name: string, isSuccess: boolean) => void,
): Promise<MentionsConfig> {
	return getMentionsConfig(
		cloudId,
		accountId,
		mentionContext,
		withAnalyticsEventProps,
		onInviteItemClick,
		userRole,
		isEligibleXProductUserInvite,
		onXProductUserInviteClick,
	);
}
