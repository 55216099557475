import { bind } from 'bind-event-listener';

/**
 * Cache queries for offline usage.
 *
 * This will possibly be replaced by service workers in the future - but this
 * temporarily improves the user experience.
 *
 * The queries get stored in a storage manager on unload.
 */
export class OfflineCache<Value> {
	private map: Map<string, Value> = new Map();
	private storageManager: StorageManager<Value>;
	private unloadCleanup = () => {};

	constructor(storageManager?: StorageManager<Value>) {
		const cacheName = 'editor:offline-network-request-cache';
		this.storageManager = storageManager ?? new IndexedDBManager(cacheName);
		this.storageManager
			.getStoredValues()
			?.then((v) => {
				this.map = v;
			})
			.catch(() => {
				// If the offline cache is rejected, use the initial empty map
			});

		if (window !== undefined) {
			this.unloadCleanup = bind(window, {
				type: 'beforeunload',
				listener: () => {
					this.update();
				},
			});
		}
	}

	private update() {
		this.storageManager.setStoredValues(this.map).catch(() => {
			// Pass, it's okay if this fails
		});
	}

	destroy() {
		this.unloadCleanup();
	}

	get(key: string): Value | undefined {
		return this.map.get(key);
	}

	set(key: string, value: Value) {
		this.map.set(key, value);
	}
}

// Can be implemented as other methods such as local storage if desired
interface StorageManager<Value> {
	getStoredValues: () => Promise<Map<string, Value>>;
	setStoredValues: (values: Map<string, Value>) => Promise<void>;
}

/** Storage manager using indexeddb */
class IndexedDBManager<Value> implements StorageManager<Value> {
	private dbPromise: Promise<IDBDatabase> | undefined = undefined;
	private objectStoreName = 'cache';
	constructor(private cacheName: string) {
		if (window?.indexedDB) {
			this.dbPromise = this.openDatabase();
		}
	}

	private openDatabase(): Promise<IDBDatabase> {
		return new Promise((resolve, reject) => {
			const request = indexedDB.open(this.cacheName, 1);
			request.onupgradeneeded = (event) => {
				const db = (event.target as IDBOpenDBRequest).result;
				if (!db.objectStoreNames.contains(this.objectStoreName)) {
					db.createObjectStore(this.objectStoreName);
				}
			};
			request.onsuccess = () => {
				resolve(request.result);
			};
			request.onerror = () => {
				reject(request.error);
			};
		});
	}

	// Retrieve a map of values for a store based on a set of keys
	private async getAllStoredValues(
		store: IDBObjectStore,
		keys: string[],
	): Promise<Map<string, Value>> {
		const allValues: Promise<[string, Value]>[] = [];

		for (const key of keys) {
			const valueRequest = store.get(key);
			allValues.push(
				new Promise<[string, Value]>((resolve, reject) => {
					valueRequest.onsuccess = () => resolve([key, JSON.parse(valueRequest.result) as Value]);
					valueRequest.onerror = () => reject(valueRequest.error);
				}),
			);
		}
		return new Map(await Promise.all(allValues));
	}

	getStoredValues(): Promise<Map<string, Value>> {
		return (
			this.dbPromise?.then((db) => {
				return new Promise<Map<string, Value>>((resolve, reject) => {
					const transaction = db.transaction(this.objectStoreName, 'readonly');

					const store = transaction.objectStore(this.objectStoreName);
					const request = store.getAllKeys();
					request.onsuccess = async () => {
						const keys = request.result as string[];
						const resultMap = await this.getAllStoredValues(store, keys);
						resolve(resultMap);
					};
					request.onerror = () => {
						reject(request.error);
					};
				});
			}) ?? Promise.reject()
		);
	}

	setStoredValues(values: Map<string, Value>): Promise<void> {
		return (
			this.dbPromise?.then((db) => {
				return new Promise<void>((resolve, reject) => {
					const transaction = db.transaction(this.objectStoreName, 'readwrite');
					const store = transaction.objectStore(this.objectStoreName);
					transaction.oncomplete = () => resolve();
					transaction.onerror = () => reject(transaction.error);

					// Clear the existing entries before setting new values
					store.clear().onsuccess = () => {
						values.forEach((value, key) => {
							store.put(JSON.stringify(value), key);
						});
					};
				});
			}) ?? Promise.reject()
		);
	}
}
